import React, { useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import styles from './styles.module.scss'
import Modal from 'react-modal';
import HeaderSpacing from "../../../../shared/components/HeaderSpace/HeaderSpace";
import meet_our_team from "../../../../data/meet_our_team";
type ContextType = {
  name: string;
  title: string;
  location: string;
  specialities: string[];
  description: string;
  imageName?: string
}
const Meet = () => {
  const createDescrHtml = (context: string) => {
    const validated = context.split('\n').map(e => e?.trim()).filter(e => !!e)

    return <p className={styles.description_item}>{validated.map(item => <>{item}<br /><br /></>)}</p>
  }
  const [modalIsOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState<ContextType | null>(null)
  function openModal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, context: ContextType) {
    e.preventDefault()
    setIsOpen(true);
    setContext(context)
  }

  const closeModal = () => {
    setContext(null)
    setIsOpen(false);
  }
  type StyleType = {
    style: CSSStyleDeclaration
  }
  const modalContent = useRef<Modal.OnAfterOpenCallbackOptions>()
  const modalOverlay = useRef<StyleType>()

  const items = meet_our_team
  const animatedClose = () => {
    if (modalContent.current) {
      modalContent.current.contentEl.style.opacity = '0';
    }
    if (modalOverlay.current) {
      modalOverlay.current.style.opacity = '0'
    }
    setTimeout(() => {
      closeModal()
      document.body.style.overflow = 'unset';
    }, 300)
  }
  return <>
    <HeaderSpacing />
    <hr style={{
      border: '0.5px solid rgb(158 158 158 / 9%)',
      margin: 0
    }} />

    <div className={styles.about}>
      <Container style={{
      }}>
        <Row>
          <Col xs={12}>
            <h2>About Urus Advisory</h2>
            <div className={styles.header_text_container}>
              <p>Urus Advisory is a London-based consulting firm that operates from 6 physical offices located in London, Riga, Istanbul, Tbilisi, Dubai and Astana. Established in 2016 with a decade of experience in delivering customised solutions, we have earned a reputation as a reliable consultant.</p>
              <br />
              <p>Our team comprises more than 20 in-house professionals, including political scientists, lawyers, IP rights specialists, investigative journalists, compliance experts, strategic risk analysts, and OSINT analysts. Together, we have completed over 5,000 projects for businesses across various industries.</p>
              <br />
              <p>Our portfolio of clients spans over 150 global companies from sectors such as Pharma, Trade and Logistics, Banking, Investment Funds, Law Firms, and others. We are proud to have played a role in their success and growth.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
    <Container style={{
    }}>
      <Row>
        <Col xs={12}>
          <h1 className={styles.meet_or_team_title}>Meet Our Team</h1>
        </Col>
        {items.map((item) => {
          return <Col sm={6} md={4} style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div className={styles.card}>
              <div className={styles.name}>{item.name}</div>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.location}>{item.location}</div>
              <div className={styles.specialties_title}>Specialities:</div>
              <ul className={styles.specialties}>
                {item.specialities.map(spec => <li>{spec}</li>)}
              </ul>
              <button disabled={!item.description && !item.imageName} onClick={(e) => openModal(e, item)}>{'View Bio'}</button>
            </div>
          </Col>
        })}
      </Row>
    </Container>
    <HeaderSpacing />
    <Modal
      onAfterOpen={(el) => {
        if (el?.contentEl.style.opacity) {
          el.contentEl.style.opacity = '1'
          document.body.style.overflow = 'hidden';
          modalContent.current = el

        }
        if (el?.overlayEl) {
          (el.overlayEl as unknown as StyleType).style.opacity = '1'
          modalOverlay.current = el.overlayEl as unknown as StyleType
        }

      }}
      isOpen={modalIsOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={animatedClose}
      style={{
        content: {
          margin: '0 auto',
          maxWidth: 1400,
          boxSizing: 'border-box',
          height: 'fit-content',
          opacity: 0,
          transition: "opacity 0.3s",
          border: 'none'
        },
        overlay: {
          transition: "opacity 0.3s",
        }
      }}
      contentLabel="Example Modal"
    >
      <div className={styles.modal}>
        <img className={styles.close} src={require('../../../../../public/images/close.svg')} onClick={animatedClose} />
        <Container>
          <Row>
            <Col lg={4} className={styles.modal_left_col}>
              <div className={styles.modal_left}>
                {context?.imageName && (
                  <div className={styles.profile_photo} style={{
                    ...(!context?.description ? { marginBottom: 0, height: 350 } : null)
                  }}>
                    {context?.imageName ? <img src={context.imageName} alt=""></img> : null}
                  </div>
                )}
                {!context?.imageName && (
                  <><br /><br /></>
                )}
                <div className={styles.modal_right}>
                  <h4>{context?.name}</h4>
                  <h5>{context?.title}</h5>
                  <ul>
                    {context?.specialities.map(spec => <li>{spec}</li>)}
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={8} className={styles.modal_right_col}>
              {context ? createDescrHtml(context.description) : null}
            </Col>
          </Row>
        </Container>
      </div>
      <br />
    </Modal>
  </>
}

export default Meet;
